<template>
  <div class="about">
    <!-- about__intro -->
    <div class="about__intro">
      <div class="about__container">
        <div class="about__intro-inner">
          <img
            class="about__logo"
            src="@/assets/img/about/about__logo.svg"
            alt=""
          />

          <h1 class="about__title-main">{{ $t("WeFillLife") }}</h1>
        </div>
      </div>
    </div>

    <!-- about__info -->
    <div class="about__info">
      <div class="container">
        <div class="about__info-inner">
          <div
            class="about__info-content"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <p class="about__info-text">
              {{ $t("aboutText") }}
            </p>
          </div>
          <div
            class="about__info-images"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div class="about__info-col about__info-left">
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img1.jpg"
                alt=""
              />
            </div>
            <div class="about__info-col about__info-right">
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img2.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about__formula -->
    <div class="about__formula">
      <div class="container">
        <div class="about__formula-box">
          <div
            class="about__formula-title about__title"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <div v-html="$t('formulaSuccess')"></div>
          </div>
          <div
            class="about__formula-inner"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--heart">
                {{ $t("formulaSuccessItemTitle_1") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_1") }}
              </p>
            </div>
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--timer">
                {{ $t("formulaSuccessItemTitle_2") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_2") }}
              </p>
            </div>
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--like">
                {{ $t("formulaSuccessItemTitle_3") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_3") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about__service -->
    <div class="about__service">
      <div class="container">
        <div class="about__service-inner about__service-top">
          <img
            src="@/assets/img/about/service.jpg"
            alt="image"
            data-aos="fade-right"
            data-aos-duration="800"
          />
          <div
            class="about__service-content"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h5 class="about__service-title about__title">
              <div v-html="$t('greatService_1')"></div>
            </h5>
            <p class="about__service-text">
              {{ $t("greatServiceItem_1_Text_1") }}
            </p>
            <p class="about__service-text">
              {{ $t("greatServiceItem_1_Text_2") }}
            </p>
          </div>
        </div>

        <div class="about__service-inner about__service-bottom">
          <div
            class="about__service-content"
            data-aos="fade-up-right"
            data-aos-duration="800"
          >
            <h5 class="about__service-title about__title">
              <div v-html="$t('greatService_2')"></div>
            </h5>
            <p class="about__service-text">
              {{ $t("greatServiceItem_2_Text_1") }}
            </p>
          </div>
          <img
            src="@/assets/img/about/quality.jpg"
            alt="image"
            data-aos="fade-up-left"
          />
        </div>
      </div>
    </div>

    <!-- about__numbers -->
    <div class="about__numbers about__numbers-company">
      <div class="container">
        <h2
          class="about__numbers-title"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <span> {{ $t("aboutNumbersTitle") }}</span>
          {{ $t("aboutNumbersSpan") }}
        </h2>
        <div class="about__slider">
          <swiper ref="swiperRef" class="" :options="swiperOption">
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-number">2007</div>
                <div class="about__slide-title">{{ $t("year") }}</div>
                <div class="about__slide-descr">
                  {{ $t("foundedKZ") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-number">2021</div>
                <div class="about__slide-title">{{ $t("year") }}</div>
                <div class="about__slide-descr">
                  {{ $t("foundedUZ") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-number">7 500</div>
                <div class="about__slide-title">{{ $t("kvm") }}</div>
                <div class="about__slide-descr">
                  {{ $t("firstHyper") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-number">20 000</div>
                <div class="about__slide-title">{{ $t("naming") }}</div>
                <div class="about__slide-descr">
                  {{ $t("presented") }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="about__slider-nav">
          <button class="about__slide-prev about__slide-btn" @click="prevSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
          <button class="about__slide-next about__slide-btn" @click="nextSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- about__loyalty -->
    <div class="about__loyalty">
      <div class="container">
        <div class="about__loyalty-inner">
          <img
            class="about__loyalty-img"
            src="@/assets/img/about/about__loyalty-img.jpg"
            alt=""
            data-aos="fade-right"
            data-aos-duration="800"
          />
          <div class="about__loyalty-content">
            <h3
              class="about__loyalty-title"
              data-aos="zoom-in"
              data-aos-duration="800"
              v-html="$t('ourProgram')"
            ></h3>
            <p
              class="about__loyalty-text"
              data-aos="zoom-in"
              data-aos-duration="800"
            >
              {{ $t("clubDescr") }}
            </p>
            <p
              class="about__loyalty-text"
              data-aos="zoom-out"
              data-aos-duration="800"
            >
              {{ $t("fullFam") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageAbout",
  components: {},
  data() {
    return {
      swiperOption: {
        sliderTo: 1,
        spaceBetween: 0,
        loop: true,
        slidesPerView: 4,

        // responsive: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4,
          },
          500: {
            slidesPerView: 3,
          },
          300: {
            slidesPerView: 2,
          },
        },
      },

      full: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
  },
};
</script>

<style>
.about__intro-banner-mobile {
  display: none;
}
@media (max-width: 800px) {
  .about__intro-img--stocks {
    top: -61%;
  }
}
@media screen and (max-width: 500px) {
  .about__intro-banner {
    display: none;
  }
  .about__intro-banner-mobile {
    display: block;
    width: 100%;
  }
}
</style>
